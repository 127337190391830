import axios from "axios";
import item from "./item";
import pagina from "./pagina";

export default class coleccion{
  nombre: string;
  handle: string;
  url: string;
  uuid: string;
  items: any[];
  visitas = 0;

  constructor(data, cache = false) {

    if(!cache){
      axios.get((new URLSearchParams(window.location.search)).get("repositorio") + "/rest/collections/" + data.uuid + "?expand=all")
        .then(response => {
          this.nombre = response.data.name;
          this.handle = response.data.handle;
          this.url = "/handle/" + response.data.handle;
          this.uuid = data.uuid;
          this.items = [];
          for(let _item of response.data.items){
            this.items.push(new item(_item));
          }

        }).catch(error => {

      })
    }else{
      this.nombre = data.nombre ?? data.name;
      this.handle = data.handle;
      this.url = "/handle/" + data.handle;
      this.uuid = data.uuid;
      this.items = [];

      if(data.items != undefined){
        for(let _item of data.items){
          this.items.push(new item(_item));
        }
      }
    }


  }

  asignarVisitas(pagina : pagina){
    if(this.url == pagina.url){
      this.visitas = pagina.visitas;
      pagina.titulo = this.nombre;
      return
    }

    if(this.items){
      for(let item of this.items){
        item.asignarVisitas(pagina);
      }
    }

  }

}
