import pagina from "./pagina";

export default class item{
  nombre: string;
  handle: string;
  url: string;
  uuid: string;
  visitas = 0;

  constructor(data) {
    this.nombre = data.nombre ?? data.name;
    this.handle = data.handle;
    this.url = "/handle/" + data.handle;
    this.uuid = data.uuid;
  }

  asignarVisitas(pagina : pagina) {
    if (this.url == pagina.url) {
      this.visitas = pagina.visitas;
      pagina.titulo = this.nombre;
      return
    }
  }
}
