import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import axios from "axios";
import environment from "../../assets/config.json";
import Chart from "chart.js/auto";
import reporte from "../Models/reporte";
import pagina from "../Models/pagina";

@Component({
  selector: 'app-grafica-paginas',
  templateUrl: './grafica-paginas.component.html',
  styleUrls: ['./grafica-paginas.component.scss']
})
export class GraficaPaginasComponent implements OnChanges{
  @Input() paginas: [pagina]
  pagina = 0;
  parametros : URLSearchParams = new URLSearchParams(window.location.search);

  ngOnChanges(changes: SimpleChanges): void {
    //console.log(changes)
  }


}
