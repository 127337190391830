import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import pagina from "../Models/pagina";

@Component({
  selector: 'app-comunidades',
  templateUrl: './comunidades.component.html',
  styleUrls: ['./comunidades.component.scss']
})
export class ComunidadesComponent{

  @Input() comunidades: any[];
  pagina = 0;
  cambio : string;
  @Input() nombre: string = "Comunidades";
  parametros : URLSearchParams = new URLSearchParams(window.location.search);


}
