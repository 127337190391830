export default class reporte{
  data: object;

  constructor() {
    this.data = {};
  }
  get(key : string, def: any = ""){
    return typeof this.data[key] == "undefined" ? def : this.data[key];
  }

  set(key, value){
    this.data[key] = value;
  }

  getList(){
    return Object.entries(this.data);
  }
}
