<div class="d-block w-100">
  <table id="tablaPaginas" class="table  table-striped table-hover">
    <thead>
    <tr>
      <th>{{ nombre }}</th>
      <th class="text-end">Vistas</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let comunidad of comunidades.slice(pagina*10, (pagina*10)+10)">
      <td style="width: 80%" class=" text-wrap">
        <p class="text-break" style="width: 80%">
          <a [attr.href]="parametros.get('repositorio') + comunidad.url" target="_blank">{{ comunidad.nombre }}</a>
        </p>
      </td>
      <td class="text-end">{{ comunidad.visitas }}</td>
    </tr>
    </tbody>
  </table>

  <nav aria-label="Page navigation example">
    <ul class="pagination justify-content-between">
      <li class="page-item" [class.disabled]="pagina == 0"><a class="page-link" href="#" (click)="$event.preventDefault(); pagina = pagina - 1">Anterior</a></li>
      <li class="page-item" [class.disabled]="(pagina*10)+10 >= comunidades.length"><a class="page-link" href="#" (click)="$event.preventDefault(); pagina = pagina + 1">Siguiente</a></li>
    </ul>
  </nav>
</div>


