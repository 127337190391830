import axios from "axios";
import coleccion from "./coleccion";
import pagina from "./pagina";

type Data = {
  subcommunities: any[],
  subComunidades: any[],
  colecciones: any[],
  collections: any[]
}
export default class comunidad{
  data: Data;
  nombre: string;
  handle: string;
  url: string;
  uuid: string;
  subComunidades: any[];
  colecciones: any[];
  visitas = 0;

  constructor(data) {

    this.data = data;
    this.nombre = data.nombre ?? data.name;
    this.handle = data.handle;
    this.url = "/handle/" + data.handle;
    this.uuid = data.uuid;
    this.subComunidades = [];
    this.colecciones = [];

  }

  async hijos(cache = false){
    if(!cache){

      if(this.data.subcommunities != undefined && this.data.subcommunities.length > 0){

        for (let sub of this.data.subcommunities){
          await axios.get((new URLSearchParams(window.location.search)).get("repositorio") + "/rest/communities/" + sub.uuid + "?expand=all")
              .then(response => {
                // @ts-ignore
                this.subComunidades.push(new comunidad(response.data));
              }).catch(error => {
            console.log(error);
          })
        }

        if(this.data.collections != undefined && this.data.collections.length > 0){
          for (let collection of this.data.collections){
            await axios.get((new URLSearchParams(window.location.search)).get("repositorio") + "/rest/collections/" + collection.uuid + "?expand=all")
                .then(response => {
                  // @ts-ignore
                  this.colecciones.push(new coleccion(response.data));
                }).catch(error => {

            })
          }
        }

      }

    }else{
      if(this.data.subComunidades.length > 0){
        for (let sub of this.data.subComunidades){
          // @ts-ignore
          this.subComunidades.push(new comunidad(sub));
        }
      }

      if(this.data.colecciones.length > 0){
        for (let sub of this.data.colecciones){
          // @ts-ignore
          this.colecciones.push(new coleccion(sub, true));
        }
      }

    }
  }

  asignarVisitas(pagina : pagina){
    if(this.url == pagina.url){
      this.visitas = pagina.visitas;
      pagina.titulo = this.nombre;
      return
    }
    for(let sub of this.subComunidades){
      sub.asignarVisitas(pagina);
    }
    for(let coleccion of this.colecciones){
      coleccion.asignarVisitas(pagina);
    }
  }

}
