import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import Chart from 'chart.js/auto';
import axios from "axios";
import environment from "../../assets/config.json";
import moment from "moment";

@Component({
  selector: 'app-grafica-ultimos-meses',
  templateUrl: './grafica-ultimos-meses.component.html',
  styleUrls: ['./grafica-ultimos-meses.component.scss']
})
export class GraficaUltimosMesesComponent implements OnChanges{

  meses: Array<string> = ["", "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiempre", "Octubre", "Noviembre", "Diciembre"];
  reporte = {};
  @Input() token: string;
  @Input() propiedades: {UA : "", GA4 : ""};

  ngOnChanges(changes: SimpleChanges): void {
    if(this.token != ''){
      this.inicarGrafico();
    }
  }

  async obtenerReporte(){

    let hoy = new Date();
    let pasado : Date = new Date();
    pasado.setMonth(pasado.getMonth()-6);

    pasado.setDate(1);
    console.time("ultimos meses")
    await axios.post("https://analyticsdata.googleapis.com/v1beta/properties/"+ this.propiedades.GA4 +":runReport", {
      "dimensions":[
        {
          "name":"yearMonth"
        }
      ],
      "metrics":[
        {
          "name":"sessions"
        }
      ],
      "dateRanges":[
        {
          "startDate": pasado.toISOString().slice(0, 10),
          "endDate": hoy.toISOString().slice(0, 10)
        }
      ]
    }, {
      headers: {
        'Content-Type': 'application/json',
        'authorization' : "Bearer " + this.token
      }
    }).then(response => {
      if(response.status == 200){
        let datos = response.data;
        for(let row in datos.rows){
          let mes = parseInt(datos.rows[row].dimensionValues[0].value.slice(-2));

          this.reporte[mes] = parseInt(datos.rows[row].metricValues[0].value);
        }
      }

    })

    console.timeEnd("ultimos meses")

    if(this.propiedades.UA){

      await axios.get("https://www.googleapis.com/analytics/v3/data/ga", {
        params: {
          ids: this.propiedades.UA,
          metrics : "ga:sessions",
          dimensions: "ga:yearMonth",
          "start-date" : pasado.toISOString().slice(0, 10),
          "end-date" : hoy.toISOString().slice(0, 10)
        },
        headers: {
          'Content-Type': 'application/json',
          'authorization' : "Bearer " + this.token
        }
      }).then(response => {
        if(response.status == 200){
          let datos = response.data;

          for(let row of datos.rows){
            let mes = parseInt(row[0].slice(-2))
            if(this.reporte[mes]){
              this.reporte[mes] += parseInt(row[1])
            }else{
              this.reporte[mes] = parseInt(row[1])
            }
          }

        }
      })

    }


  }

  async inicarGrafico(){

    for(let x = 6; x >= 0; x--){
      let mes = moment().subtract(x, "months").month() + 1;
      // @ts-ignore
      this.reporte[mes] = 0;
    }

    let labels = [];
    let datos = [];

    await this.obtenerReporte();

    // @ts-ignore
    for(let valor of Object.entries(this.reporte)){
      labels.push(this.meses[valor[0]]);
      datos.push(valor[1])
    }

    // @ts-ignore
    new Chart(document.getElementById("ultimosMeses"), {
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Sesiones en los últimos meses',
            data: datos
          }
        ]
      }
    })
  }

}
