<div class="container-fluid py-3">

  <nav class="navbar navbar-light bg-white">
    <div class="container-fluid">
      <h1>
        <a class="navbar-brand">{{ propiedades.nombre }} :: Analitycs</a>
      </h1>
      <form class="d-flex">
        <input class="form-control me-2" type="text" id="fecha">
      </form>
    </div>
  </nav>

  <div class="container-fluid" *ngIf="!loading && !error">
    <div class="row alert text-white py-3" [ngStyle]="{'background-color': propiedades.color }">
      <div class="col-3">
        <h6>Usuario</h6>
        <h5>{{ reporte.get("totalUsers") }}</h5>
      </div>
      <div class="col-3">
        <h6>Sesiones</h6>
        <h5>{{ reporte.get("sessions") }}</h5>
      </div>
      <div class="col-3">
        <h6>Visitas a páginas</h6>
        <h5>{{ reporte.get("screenPageViews") }}</h5>
      </div>
      <div class="col-3">
        <h6>Porcentaje de rebote</h6>
        <h5>{{ Math.floor(reporte.get("bounceRate")*100) }}%</h5>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <h3 class="mb-5">Sesiones en los últimos meses</h3>
        <app-grafica-ultimos-meses [token]="token" [propiedades]="propiedades"></app-grafica-ultimos-meses>
      </div>
      <div class="col-6">
        <h3>Sesiones por país</h3>
        <app-grafica-paises [fecha1]="fecha1" [fecha2]="fecha2" [propiedades]="propiedades" [token]="token"></app-grafica-paises>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h3>Páginas mas visitadas</h3>
        <app-grafica-paginas [paginas]="paginas"></app-grafica-paginas>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-4">
        <h3>Comunidades</h3>
        <app-comunidades [comunidades]="comunidadesOrdenadas()"></app-comunidades>
      </div>
      <div class="col-12 col-md-4">
        <h3>Subcomunidades</h3>
        <app-comunidades [comunidades]="subcomunidadesOrdenadas()"  [nombre]="'Sub comunidades'"></app-comunidades>
      </div>
      <div class="col-12 col-md-4">
        <h3>Colecciones</h3>
        <app-comunidades [comunidades]="coleccionesOrdenadas()" [nombre]="'Colecciones'"></app-comunidades>
      </div>
    </div>



  </div>

  <div class="container" *ngIf="error">
    <h2 class="alert alert-danger text-center">{{ mensaje }}</h2>
  </div>


  <div class="container container d-flex justify-content-center  align-items-center flex-column" style="height: 70vh" *ngIf="loading">
    <h3 class="text-center mb-4">Cargando...</h3>
    <div class="spinner-grow" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

</div>
