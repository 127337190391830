import {Component, OnInit} from '@angular/core';
import * as jose from 'jose'
import data from "../assets/google.json";
import axios from "axios";
import reporte from "./Models/reporte";
import environment from "../assets/config.json";
import flatpickr from "flatpickr";
import pagina from "./Models/pagina";
import comunidad from "./Models/comunidad";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'reportes-angular';
  reporte : reporte = new reporte();
  parametros : URLSearchParams = new URLSearchParams(window.location.search);
  comunidades: any[];
  loading: boolean = true;
  fecha1 : Date;
  fecha2: Date;
  token: string;
  propiedades : {UA : "", GA4 : "", nombre: "", color: ""};
  error: boolean = false;
  mensaje: string = "";
  paginas: [pagina];

  ngOnInit() {

    if(this.parametros.get("repositorio") == null){
      this.loading = false;
      this.error = true;
      this.mensaje = "No se ha seleccionado ningun repositorio";
      return;
    }

    if(environment[this.parametros.get("repositorio")] == undefined){
      this.loading = false;
      this.error = true;
      this.mensaje = "No se ha seleccionado ningun repositorio 2";
      return;
    }

    this.propiedades = environment[this.parametros.get("repositorio")];

    if(localStorage.getItem("access_token") == null){

      this.login().then(async success => {
        if(success){
          await this.obtenerComunidades();
        }
      });

    }else{

      this.token = localStorage.getItem("access_token");
      this.obtenerComunidades();

    }
  }

  async getToken(){

    const alg = 'RS256'

    const privateKey = await jose.importPKCS8(data.private_key, alg)

    const jwt = await new jose.SignJWT({ 'scope': "https://www.googleapis.com/auth/analytics" })
      .setProtectedHeader({ alg: alg, typ: "JWT" })
      .setIssuedAt()
      .setIssuer('reportes@reportesga4.iam.gserviceaccount.com')
      .setAudience('https://oauth2.googleapis.com/token')
      .setExpirationTime('1h')
      .sign(privateKey)

    return jwt;
  }

  async login(){
    let token =  await this.getToken();
    let success = false;
    await axios.post("https://oauth2.googleapis.com/token", {
      "assertion" : token,
      "grant_type" : "urn:ietf:params:oauth:grant-type:jwt-bearer"
    }).then(response => {
      if(response.status == 200){
        localStorage.setItem("access_token", response.data.access_token);
        this.token = response.data.access_token;
        success = true;
      }else{
        console.log("error");
      }
    }).catch(error => {
      console.log(error)
    })

    return success;

  }

  iniciarCalendario(){
    this.fecha1 = new Date();
    this.fecha2 = new Date();

    this.fecha1.setMonth(this.fecha1.getMonth()-1)

    flatpickr("#fecha", {
      mode: "range",
      dateFormat: "Y-m-d",
      defaultDate : [this.fecha1.toISOString().slice(0, 10), this.fecha2.toISOString().slice(0, 10)],
      onChange: (selectedDates, dateStr, instance) => {
        if(selectedDates.length == 2){
          this.loading = true;
          this.fecha1 = selectedDates[0];
          this.fecha2 = selectedDates[1];
          this.obtenerReporte()
          this.obtenerReportePaginas();
        }
      },
      onReady: () => {
        this.obtenerReporte()
        this.obtenerReportePaginas();
      }
    });
  }

  async obtenerReporte(){
    this.reporte = new reporte();
    // @ts-ignore
    await axios.post("https://analyticsdata.googleapis.com/v1beta/properties/"+ this.propiedades.GA4 +":runReport", {
      "metrics" : [
        {
          "name" : "totalUsers"
        },
        {
          "name" : "sessions"
        },
        {
          "name" : "bounceRate"
        },
        {
          "name" : "screenPageViews"
        }
      ],
      "dateRanges" : [
        {
          "startDate": this.fecha1.toISOString().slice(0, 10),
          "endDate": this.fecha2.toISOString().slice(0, 10)
        }
      ]
    }, {
      headers: {
        'Content-Type': 'application/json',
        'authorization' : "Bearer " + this.token
      }
    }).then(response => {

      if(response.status == 200){
        let datos = response.data;
        for(let row in datos.metricHeaders){
          this.reporte.set(datos.metricHeaders[row].name, datos.rows[0].metricValues[row].value);
        }
        this.loading = false;
      }

    }).catch(error => {

      if(error.response.status == 401){
        console.log("recargar")
        localStorage.removeItem("access_token");
        window.location.reload()
      }
    })

    if(this.propiedades.UA) {

      await axios.get("https://www.googleapis.com/analytics/v3/data/ga", {
        params: {
          ids: this.propiedades.UA,
          metrics: "ga:users,ga:sessions,ga:bounceRate,ga:pageviews",
          "start-date": this.fecha1.toISOString().slice(0, 10),
          "end-date": this.fecha2.toISOString().slice(0, 10)
        },
        headers: {
          'Content-Type': 'application/json',
          'authorization': "Bearer " + this.token
        }
      }).then(response => {
        if (response.status == 200) {
          let datos = response.data;

          this.reporte.set("totalUsers", parseInt(this.reporte.get("totalUsers", 0)) + parseInt(datos.totalsForAllResults["ga:users"]));
          this.reporte.set("sessions", parseInt(this.reporte.get("sessions", 0)) + parseInt(datos.totalsForAllResults["ga:sessions"]));

          let ga4Bounce = parseFloat(this.reporte.get("bounceRate", 0));
          let uaBounce = parseFloat(datos.totalsForAllResults["ga:bounceRate"]);

          let bounce = ga4Bounce + uaBounce;

          let bounces = (ga4Bounce > 0 ? 1 : 0) + (uaBounce > 0 ? 1 : 0);

          this.reporte.set("bounceRate", (bounce / bounces));
          this.reporte.set("screenPageViews", parseInt(this.reporte.get("screenPageViews", 0)) + parseInt(datos.totalsForAllResults["ga:pageviews"]));

          this.loading = false;
        }
      })

    }

  }

  async obtenerComunidades(){
    this.comunidades = [];

    if(localStorage.getItem(this.parametros.get("repositorio") + "|comunidades")){
      let comunidades = JSON.parse(localStorage.getItem(this.parametros.get("repositorio") + "|comunidades"));

      for (let _comunidad of comunidades){
        let laComunidad = new comunidad(_comunidad);
        await laComunidad.hijos(true)
        this.comunidades.push(laComunidad);
      }
      this.loading = false;
      this.iniciarCalendario();
    }else{
      await axios.get(this.parametros.get("repositorio") + "/rest/communities?expand=parentCommunity")
        .then(async response => {
          if(response.status == 200){
            let datos = response.data;
            this.comunidades = [];
            for(let dato of datos){
              if(dato.parentCommunity == null){
                await axios.get(this.parametros.get("repositorio") + "/rest/communities/" + dato.uuid + "?expand=all").then(async response => {
                  let laComunidad = new comunidad(response.data);
                  await laComunidad.hijos(false);
                  this.comunidades.push(laComunidad);

                }).catch(error => {

                })
              }
            }

            this.loading = false;
            this.iniciarCalendario()
          }
        })
      localStorage.setItem(this.parametros.get("repositorio") + "|comunidades", JSON.stringify(this.comunidades));
      console.log("final ", this.comunidades)
    }

  }

  async obtenerReportePaginas(){

    // @ts-ignore
    this.paginas = [];

    await axios.post("https://analyticsdata.googleapis.com/v1beta/properties/"+ this.propiedades.GA4 +":runReport", {
      "dimensions":[
        {
          "name":"pagePath"
        }
      ],
      "metrics":[
        {
          "name":"sessions"
        }
      ],
      "dateRanges":[
        {
          "startDate": this.fecha1.toISOString().slice(0, 10),
          "endDate": this.fecha2.toISOString().slice(0, 10)
        }
      ]
    }, {
      headers: {
        'Content-Type': 'application/json',
        'authorization' : "Bearer " + this.token
      }
    }).then(response => {
      if(response.status == 200){
        let datos = response.data;
        for(let row in datos.rows){
          this.paginas.push(new pagina(datos.rows[row].dimensionValues[0].value, parseInt(datos.rows[row].metricValues[0].value)));
        }
        for(let pagina of this.paginas){
          this.asignarVisitas(pagina)
        }

        this.paginas.sort((a,b) => {
          return a.visitas - b.visitas;
        })

        this.paginas.reverse();


      }
    })
  }

  asignarVisitas(pagina){
    for(let comunidad of this.comunidades){
      comunidad.asignarVisitas(pagina);
    }
  }

  comunidadesOrdenadas(){
    return this.comunidades.sort((a, b) => {
      return a.visitas - b.visitas;
    }).reverse();
  }

  subcomunidadesOrdenadas(){

    let subs = [];

    for(let comunidad of this.comunidades){
      if(comunidad.subComunidades.length > 0){
        subs = subs.concat(comunidad.subComunidades);
      }
    }

    return subs.sort((a, b) => {
      return a.visitas - b.visitas;
    }).reverse();
  }

  coleccionesOrdenadas(){

    let subs = [];

    for(let comunidad of this.comunidades){
      if(comunidad.colecciones.length > 0){
        subs = subs.concat(comunidad.colecciones);
      }
    }

    return subs.sort((a, b) => {
      return a.visitas - b.visitas;
    }).reverse();
  }

  protected readonly Math = Math;
}
