<ul class="nav nav-tabs" id="paisesTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#mapaPaises" type="button" role="tab" aria-controls="home" aria-selected="true">Mapa</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#tablaPaises" type="button" role="tab" aria-controls="profile" aria-selected="false">Tabla</button>
  </li>
</ul>

<div class="tab-content" id="paisesTabContent">
  <div class="tab-pane fade show active" id="mapaPaises" role="tabpanel" aria-labelledby="home-tab">


    <canvas id="paises"></canvas>

  </div>
  <div class="tab-pane fade" id="tablaPaises" role="tabpanel" aria-labelledby="profile-tab">
    <table class="table table-striped">
      <thead>
      <tr>
        <th width="50%">País</th>
        <th></th>
        <th>Visitas</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let pais of listadoPaises.slice(pagina*5, (pagina*5)+5)">
        <td>{{ pais.pais }}</td>
        <td><button class="btn btn-link" (click)="paisSelected = pais;mostrarModalCiudades()" >Ver ciudades</button></td>
        <td class="text-end">{{ pais.visitas }}</td>
      </tr>
      </tbody>
    </table>

    <nav aria-label="Page navigation example">
      <ul class="pagination justify-content-between">
        <li class="page-item" [class.disabled]="pagina == 0"><a class="page-link" href="#" (click)="$event.preventDefault(); pagina = pagina - 1">Anterior</a></li>
        <li class="page-item" [class.disabled]="(pagina*5)+5 >= listadoPaises.length"><a class="page-link" href="#" (click)="$event.preventDefault(); pagina = pagina + 1">Siguiente</a></li>
      </ul>
    </nav>

  </div>
</div>

<div class="modal fade" id="ciudades" tabindex="-1" aria-labelledby="ciudades" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Ciudades {{ paisSelected.pais }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" style="max-height: 80vh;overflow: auto;">
        <table class="table table-striped">
          <thead>
          <tr>
            <th width="80%">Ciudad</th>
            <th>Visitas</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let ciudad of listadoCiudades">
            <td>{{ ciudad.ciudad }}</td>
            <td class="text-end">{{ ciudad.visitas }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
