export default class pagina{
  url: string;
  visitas: number;
  titulo: string = "";
  parametros : URLSearchParams = new URLSearchParams(window.location.search);

  constructor(url, visitas) {

    this.url = url
    this.visitas = visitas;
    this.titulo = url;
    if(url == "/"){
      this.titulo = "Inicio";
    }

  }

}
