import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import axios from "axios";
import environment from "../../assets/config.json";
import paises from "../../assets/paises.json";
import reporte from "../Models/reporte";
import Chart from "chart.js/auto";
import {ChoroplethController, GeoFeature, ColorScale, ProjectionScale, topojson, Feature} from 'chartjs-chart-geo';
import { Tab, Modal } from "bootstrap"

@Component({
  selector: 'app-grafica-paises',
  templateUrl: './grafica-paises.component.html',
  styleUrls: ['./grafica-paises.component.scss']
})
export class GraficaPaisesComponent implements OnInit, OnChanges{

  @Input() fecha1: Date;
  @Input() fecha2: Date;
  @Input() propiedades: {UA : "", GA4 : ""};
  @Input() token: string;
  paisSelected = {
    pais: ""
  }
  reporte : reporte = new reporte();
  listadoPaises = [{
    pais : "",
    visitas: 0
  }];
  pagina = 0;
  modalCiudades = null;
  listadoCiudades = [{
    ciudad : "",
    visitas: 0
  }];
  ngOnInit(): void {
    Chart.register(ChoroplethController, GeoFeature, ColorScale, ProjectionScale);
    var triggerTabList = [].slice.call(document.querySelectorAll('#myTab a'))
    triggerTabList.forEach(function (triggerEl) {
      var tabTrigger = new Tab(triggerEl)

      triggerEl.addEventListener('click', function (event) {
        event.preventDefault()
        tabTrigger.show()
      })
    })

    this.modalCiudades = new Modal(document.getElementById('ciudades'), {})

  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes)
    this.obtenerPaises();
  }


  async crearGrafica(){

    // @ts-ignore
    const countries = topojson.feature(paises, paises.objects.countries).features;
    console.log(countries);
    // @ts-ignore
    new Chart(document.getElementById("paises"), {
      type: 'choropleth',
      data: {
        labels: countries.map((d) => d.properties.name),
        datasets: [{
          label: 'Countries',
          data: countries.map(d => {
            return {
              feature: d,
              value: parseInt(this.reporte.get(d.properties.name, 0))
            }
          }),
        }]
      },
      options: {
        showOutline: false,
        showGraticule: false,
        plugins: {
          legend: {
            display: false
          },
        },
        scales: {
          projection: {
            axis: 'x',
            projection: 'equalEarth'
          }
        }
      }
    });

    this.listadoPaises = [];
    for (let pais of this.reporte.getList()){
      if(pais[1] > 0){
        this.listadoPaises.push({
          pais : pais[0],
          visitas: pais[1]
        })
      }

    }

    this.listadoPaises.sort((a, b) => {
      return a.visitas - b.visitas;
    })

    this.listadoPaises.reverse();

  }


  async obtenerPaises(){
    await axios.post("https://analyticsdata.googleapis.com/v1beta/properties/"+ this.propiedades.GA4 +":runReport", {
      "dimensions":[
        {
          "name":"country"
        }
      ],
      "metrics":[
        {
          "name":"sessions"
        }
      ],
      "dateRanges":[
        {"startDate": this.fecha1.toISOString().slice(0, 10),"endDate": this.fecha2.toISOString().slice(0, 10)}
      ]
    }, {
      headers: {
        'Content-Type': 'application/json',
        'authorization' : "Bearer " + localStorage.getItem("access_token")
      }
    }).then(response => {
      if(response.status == 200){
        let datos = response.data;
        for(let row in datos.rows){
          this.reporte.set(datos.rows[row].dimensionValues[0].value, datos.rows[row].metricValues[0].value);
        }
      }
    })

    if(this.propiedades.UA){

      await axios.get("https://www.googleapis.com/analytics/v3/data/ga", {
        params: {
          ids: this.propiedades.UA,
          metrics : "ga:sessions",
          dimensions: "ga:country",
          "start-date" : this.fecha1.toISOString().slice(0, 10),
          "end-date" : this.fecha2.toISOString().slice(0, 10)
        },
        headers: {
          'Content-Type': 'application/json',
          'authorization' : "Bearer " + this.token
        }
      }).then(response => {
        if(response.status == 200){
          let datos = response.data;
          if(datos.rows !== undefined){
            for(let row of datos.rows){
              this.reporte.set(row[0], this.reporte.get(row[0], 0) + parseInt(row[1]));
            }
          }
        }
      })

    }


    this.crearGrafica()

  }

  async obtenerCiudades(){
    await axios.post("https://analyticsdata.googleapis.com/v1beta/properties/"+ this.propiedades.GA4 +":runReport", {
      "dimensions":[
        {
          "name":"country"
        },
        {
          "name":"city"
        }
      ],
      "metrics":[
        {
          "name":"sessions"
        }
      ],
      "dateRanges":[
        {"startDate": this.fecha1.toISOString().slice(0, 10),"endDate": this.fecha2.toISOString().slice(0, 10)}
      ],
      "dimensionFilter":{
        "filter" : {
          "fieldName" : "country",
          "stringFilter" : {
            "matchType" : "EXACT",
            "value" : this.paisSelected.pais
          }
        }
      }
    }, {
      headers: {
        'Content-Type': 'application/json',
        'authorization' : "Bearer " + localStorage.getItem("access_token")
      }
    }).then(response => {
      if(response.status == 200){
        let datos = response.data;
        this.listadoCiudades = [];
        for(let row of datos.rows){
          this.listadoCiudades.push({
            ciudad: row.dimensionValues[1].value,
            visitas: row.metricValues[0].value
          })
        }
      }
    })


    this.crearGrafica()

  }

  mostrarModalCiudades(){
    this.obtenerCiudades();
    this.modalCiudades.show();
  }

}
