<div class="d-block w-100">
  <table id="tablaPaginas" class="table  table-striped table-hover">
    <thead>
    <tr>
      <th>Página</th>
      <th class="text-end">Vistas</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let pagina of paginas.slice(pagina*10, (pagina*10)+10)">
      <td style="width: 80%" class=" text-wrap">
        <p class="text-break" style="width: 80%">
          <a [attr.href]="parametros.get('repositorio') + pagina.url" target="_blank">{{ pagina.titulo }}</a>
        </p>
      </td>
      <td class="text-end">{{ pagina.visitas }}</td>
    </tr>
    </tbody>
  </table>

  <nav aria-label="Page navigation example">
    <ul class="pagination justify-content-between">
      <li class="page-item" [class.disabled]="pagina == 0"><a class="page-link" href="#" (click)="$event.preventDefault(); pagina = pagina - 1">Anterior</a></li>
      <li class="page-item" [class.disabled]="(pagina*5)+5 >= paginas.length"><a class="page-link" href="#" (click)="$event.preventDefault(); pagina = pagina + 1">Siguiente</a></li>
    </ul>
  </nav>
</div>

