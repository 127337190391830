import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { GraficaUltimosMesesComponent } from './grafica-ultimos-meses/grafica-ultimos-meses.component';
import { GraficaPaisesComponent } from './grafica-paises/grafica-paises.component';
import { GraficaPaginasComponent } from './grafica-paginas/grafica-paginas.component';
import { ComunidadesComponent } from './comunidades/comunidades.component';

@NgModule({
  declarations: [
    AppComponent,
    GraficaUltimosMesesComponent,
    GraficaPaisesComponent,
    GraficaPaginasComponent,
    ComunidadesComponent
  ],
    imports: [
        BrowserModule
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
